// Criando um contexto para o estado isNavFixed
import React, { createContext, useContext, useState } from 'react';

const NavContext = createContext();

export const useNavContext = () => useContext(NavContext);

export const NavProvider = ({ children }) => {
    const [isNavFixed, setIsNavFixed] = useState(false);

    return (
        <NavContext.Provider value={{ isNavFixed, setIsNavFixed }}>
            {children}
        </NavContext.Provider>
    );
};
