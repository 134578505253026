import React from 'react';
import Topo from './components/topo';
import Menu from './components/menu';
import Carousel from './components/carousel';
import AssistenciaEspiritual from './components/assistenciaespiritual';
import EvangelizacaoInfantil from './components/evangelizacaoinfantil';
import MocidadeEspirita from './components/mocidadeespirita';
import EvangelhoLar from './components/evangelholar';
import Slogan from './components/slogan';
import Cursos from './components/cursos';
import Sobre from './components/sobre';
import Aee from './components/aee';
import Contato from './components/contato';
import Rodape from './components/rodape';
import { NavProvider } from './contexts/navContext';

function App() {
  return (
    <NavProvider>
      <section id="home">
        <Topo />
        <Menu />
        <Carousel />
      </section>

      <section id="atividades">
        <AssistenciaEspiritual />
        <EvangelizacaoInfantil />
        <MocidadeEspirita />
        <EvangelhoLar />
        <Slogan />
      </section>

      <section id="cursos">
        <Cursos />
      </section>

      <section id="sobre">
        <Sobre />
      </section>

      <section id="aee">
        <Aee />
      </section>

      <section id="contato">
        <Contato />
      </section>

      <Rodape />
    </NavProvider>
  );
}

export default App;
