import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';

export default function AssistenciaEspiritual() {
    return (
        <div className="bg-gray-100 py-16">
            <div className="container mx-auto">
                <div className="text-center pb-14">
                    <h1 className="font-serif text-4xl font-medium text-green-600">Assistência Espiritual</h1>
                </div>
                <div className="flex flex-wrap items-start justify-center lg:justify-between">
                    <div className="w-full lg:w-2/3">
                        <h4 className="font-thin text-2xl mb-2 text-green-600">Reequilíbrio espiritual</h4>
                        <p className="text-lg leading-relaxed mb-6 text-slate-600">
                            A assistência espiritual é um conjunto de atividades organizadas de modo a proporcionar
                            reequilíbrio espiritual à coletividade que busca o centro espírita. Está fundamentada em passes
                            magnéticos, preleções evangélicas e atividades mediúnicas.
                        </p>
                        <h4 className="font-thin text-2xl mb-2 text-green-600">Tendo como principal objetivo:</h4>
                        <ul className="list-none pl-5">
                            <li className="text-lg mb-3 text-slate-600">
                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                Promover o equilíbrio dos assistidos (encarnados e desencarnados).
                            </li>
                            <li className="text-lg text-slate-600">
                                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                Servir de campo de testemunho evangélico aos servidores e Discípulos de Jesus.
                            </li>
                        </ul>
                    </div>
                    <div className="w-full lg:w-1/4 mt-8 lg:mt-0">
                        <div className="bg-red-500 text-white p-6 rounded-lg">
                            <p className="text-xl font-semibold mb-4 text-center">
                                <FontAwesomeIcon icon={faClock} className="mr-2" />
                                Horários
                            </p>
                            <ul className="list-none pl-5">
                                <li className="text-md mb-3 text-center">
                                    Segunda-Feira e Terça-Feira às 19:00
                                </li>
                                <li className="text-md mb-3 text-center">
                                    Quarta-Feira às 14:00
                                </li>
                                <li className="text-md text-center">
                                    Domingo às 18:30
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}