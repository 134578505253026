import React, { useState, useEffect, useCallback } from 'react';
import { MenuIcon } from '@heroicons/react/outline';
import { Link } from 'react-scroll';
import '../style/components/menu.css';
import logoImg from '../style/images/LogoCEEA.JPG';
import { useNavContext } from '../contexts/navContext';

export default function Menu() {
    const [selectedItem, setSelectedItem] = useState('home');
    const [menuOpen, setMenuOpen] = useState(false);
    const { isNavFixed, setIsNavFixed } = useNavContext();

    const isMobileDevice = () => {
        return window.innerWidth <= 768 || 'ontouchstart' in window;
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const handleItemClick = (itemId) => {
        closeMenu();
    };

    const handleScroll = useCallback(() => {
        const sections = document.querySelectorAll('section');
        const scrollPosition = window.scrollY;

        if (window.scrollY > 100) {
            setIsNavFixed(true);
        } else {
            setIsNavFixed(false);
        }

        sections.forEach((section) => {
            const offset = -70; // Ajuste o valor do offset conforme necessário
            const top = section.offsetTop + offset;
            const height = section.offsetHeight;

            if (scrollPosition >= top && scrollPosition < top + height) {
                setSelectedItem(section.id);
            }
        });
    }, [setIsNavFixed]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <nav
            id="navbar-menu"
            className={`bg-gray-200 ${menuOpen ? 'h-40' : 'h-14'} ${isNavFixed ? 'fixed-nav' : ''
                }`}
            role="navigation"
        >
            <div
                className={`container mx-auto mt-0 h-full flex ${menuOpen ? 'items-start' : 'items-center'
                    } `}
            >
                <button
                    type="button"
                    className="ml-2 lg:hidden focus:outline-none"
                    onClick={toggleMenu}
                >
                    <MenuIcon className="w-6 h-6" />
                </button>
                {(isNavFixed || isMobileDevice()) && (
                    <a className="ml-5" href="/">
                        <img
                            alt="CEEA"
                            src={logoImg}
                            className="w-28 h-14"
                        />
                    </a>
                )}
                <div
                    className={`lg:flex lg:space-x-8 lg:mx-36 ${menuOpen ? 'bg-gray-200' : 'h-14 hidden'
                        }`}
                >
                    <Link
                        to="home"
                        smooth={true}
                        duration={500}
                        className={`text-gray-800 h-full px-2 flex items-center ${selectedItem === 'home'
                            ? 'bg-green-700 text-white'
                            : 'hover:text-gray-500'
                            }`}
                        onClick={() => handleItemClick('home')}
                        style={{ cursor: 'pointer' }}
                        offset={-50}
                    >
                        Home
                    </Link>
                    <Link
                        to="atividades"
                        smooth={true}
                        duration={500}
                        className={`text-gray-800 h-full px-2 flex items-center ${selectedItem === 'atividades'
                            ? 'bg-green-700 text-white'
                            : 'hover:text-gray-500'
                            }`}
                        onClick={() => handleItemClick('atividades')}
                        style={{ cursor: 'pointer' }}
                        offset={-50}
                    >
                        Atividades
                    </Link>
                    <Link
                        to="cursos"
                        smooth={true}
                        duration={500}
                        className={`text-gray-800 h-full px-2 flex items-center ${selectedItem === 'cursos'
                            ? 'bg-green-700 text-white'
                            : 'hover:text-gray-500'
                            }`}
                        onClick={() => handleItemClick('cursos')}
                        style={{ cursor: 'pointer' }}
                        offset={-50}
                    >
                        Cursos
                    </Link>
                    <Link
                        to="sobre"
                        smooth={true}
                        duration={500}
                        className={`text-gray-800 h-full px-2 flex items-center ${selectedItem === 'sobre'
                            ? 'bg-green-700 text-white'
                            : 'hover:text-gray-500'
                            }`}
                        onClick={() => handleItemClick('sobre')}
                        style={{ cursor: 'pointer' }}
                        offset={-50}
                    >
                        Sobre
                    </Link>
                    <Link
                        to="aee"
                        smooth={true}
                        duration={500}
                        className={`text-gray-800 h-full px-2 flex items-center ${selectedItem === 'aee'
                            ? 'bg-green-700 text-white'
                            : 'hover:text-gray-500'
                            }`}
                        onClick={() => handleItemClick('aee')}
                        style={{ cursor: 'pointer' }}
                        offset={-50}
                    >
                        AEE
                    </Link>
                    <Link
                        to="contato"
                        smooth={true}
                        duration={500}
                        className={`text-gray-800 h-full px-2 flex items-center ${selectedItem === 'contato'
                            ? 'bg-green-700 text-white'
                            : 'hover:text-gray-500'
                            }`}
                        onClick={() => handleItemClick('contato')}
                        style={{ cursor: 'pointer' }}
                        offset={-50}
                    >
                        Contato
                    </Link>
                </div>
            </div>
        </nav>
    );
}
