import React from 'react';
import fundoImg from '../style/images/imagem5.jpg';
import '../style/components/slogan.css'

export default function Slogan() {
    return (
        <div className="relative">
            <img src={fundoImg} alt="planeta" className="h-52 w-full" />
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="absolute inset-0 top-10 left-10 right-10 lg:left-80 lg:right-80 text-center">
                <div className="carousel-text animate-slide-left">
                    <h1 className="text-md lg:text-3xl text-white leading-normal">Não há fé inabalável senão aquela que pode encarar a razão face a face, em todas as épocas da Humanidade.</h1>
                    <h3 className="text-md lg:text-2xl pt-5 text-yellow-400 text-right">Allan Kardec</h3>
                </div>
            </div>
        </div>
    );
}
