import React from 'react';
import mocidadeImg from '../style/images/SegundaMocidade.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

export default function MocidadeEspirita() {
    return (
        <div className="bg-gray-100 py-10">
            <div className="container mx-auto">
                <div className="text-center pb-14">
                    <h1 className="font-serif text-4xl font-medium text-green-600">Mocidade Espírita</h1>
                </div>
                <div className="flex flex-wrap items-start justify-center lg:justify-between">
                    <div className="w-full lg:w-2/3">
                        <h4 className="font-thin text-2xl mb-2 text-green-600">Curso de espiritismo para jovens</h4>
                        <p className="text-lg leading-relaxed mb-6 text-slate-600">
                            A mocidade espírita é um curso de espiritismo para jovens e também um departamento do centro espírita
                            dedicado à juventude.
                        </p>
                        <h4 className="font-thin text-2xl mb-2 text-green-600">Uma base sólida</h4>
                        <p className="text-lg leading-relaxed mb-6 text-slate-600">
                            Tem como principal objetivo, dar ao jovem uma formação moral e intelectual que seja a base sólida sobre a qual
                            ele construirá sua vida e onde encontrará forças para enfrentar a si mesmo e ao mundo diante da realidade em
                            que vive e caminhar para frente por seus próprios recursos. Além de preparar o jovem para assumir uma
                            posição participativa dentro da Doutrina Espírita e do grupo que se filia, para que, através do seu dinamismo e
                            coragem, possamos realmente exteriorizar o espiritismo de maneira atuante.
                        </p>
                        <p className="text-lg leading-relaxed mb-6 text-slate-600">
                            Dentro disso, observamos que a mocidade é a continuação da evangelização infantil, numa fase que para o
                            jovem 'adolescer' corresponde a muitas mudanças e transformações físicas e psicológicas que lhe perturbam.
                        </p>
                        <h4 className="font-thin text-2xl mb-2 text-green-600">Livre-arbítrio</h4>
                        <p className="text-lg leading-relaxed mb-6 text-slate-600">
                            É importante salientar que o objetivo da mocidade não é modificar o comportamento ou personalidade do jovem.
                            Isto seria uma violência para com ele, e para com o seu livre-arbítrio, sua livre expressão.
                        </p>
                    </div>
                    <div className="w-full lg:w-1/4 mt-8 lg:mt-0">
                        <div className="bg-red-500 text-white p-6 rounded-lg mb-8">
                            <p className="text-xl font-semibold mb-4 text-center">
                                <FontAwesomeIcon icon={faClock} className="mr-2" />
                                Horários
                            </p>
                            <ul className="list-none ml-6">
                                <li className="mb-2">Sábado das 11:15 às 12:45</li>
                                <li>A partir dos 14 anos</li>
                            </ul>
                        </div>
                        <div className="mb-8">
                            <img src={mocidadeImg} alt="Mocidade" className="mx-auto mb-4 rounded-lg" />
                            <div className="text-center">
                                <a href="https://mocidade.evangelhoeamor.com.br" target="_blank" rel="noopener noreferrer" className="inline-block bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">Convite</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
