import React from 'react';

export default function Sobre() {
    return (
        <div className="py-16">
            <div className="container mx-auto">
                <div className="text-center pb-14">
                    <h1 className="font-serif text-4xl font-medium text-green-600">Sobre</h1>
                </div>
                <p className="text-center text-lg text-slate-600 pb-5">Podemos resumir o QUEM SOMOS, em apenas uma estrofe que encontramos no Hino do Discípulo, que diz:</p>
                <h3 className="text-center text-2xl text-green-600 pb-10">Somos servos de Jesus na aliança de seu amor, e a força que nos conduz é o Evangelho Redentor.</h3>
                <div className="hidden lg:flex">
                    <div className="w-full lg:w-1/2">
                        <p className="text-md text-slate-600 pb-4">
                            A <strong className="text-green-600">Centro Espírita Evangelho e Amor</strong> é um grupo integrado a <strong>Aliança Espírita Evangélica</strong>, onde temos como
                            ideal à vivência espírita, baseada em um programa de trabalho e fraternização.
                        </p>
                        <p className="text-md text-slate-600 pb-4">
                            Procuramos mostrar a todos aqueles que adentram a nossa Casa a importância da vivência dos ensinamentos
                            que encontramos na Doutrina Espírita, que veio dar cumprimento aos ensinamentos de Jesus. Apenas a vivência do
                            Cristianismo é que nos conduz a renovação dos nossos sentimentos, pensamentos e atitudes, despertando nossos
                            ideais divinos.
                        </p>
                        <p className="text-md text-slate-600 pb-4">
                            Para que este ideal cristão possa ser efetivado, oferecemos para nossos assistidos a Escola de Aprendizes
                            do Evangelho, Curso Básico de Espiritismo, Assistência Espiritual, Curso de Médiuns, Evangelização Infantil,
                            Escola de Pais, Mocidade Espírita e Evangelho no Lar.
                        </p>
                        <p className="text-md text-slate-600 pb-4">
                            Através de tudo isso, procuramos mostrar a todos que adentram a esta Casa, que a Doutrina Espírita não é
                            apenas consoladora, mas fundamentalmente redentora, nos indicando <strong className="text-green-600">Caminhos de Libertação (*) </strong>
                            para que possamos vencer o homem velho que ainda habita em nosso íntimo e consequentemente despertar o homem novo,
                            vivenciando os ensinamentos de Jesus.
                        </p>
                        <p className="text-md text-slate-600 pb-4">
                            Mas após esta breve introdução, vale à pena contarmos um pouco da nossa história.
                        </p>
                        <h4 className="font-thin text-2xl mb-2 text-green-600">Nossa História</h4>
                        <p className="text-md text-slate-600 pb-4">
                            Tudo começou em Junho de 1999, onde um grupo de pessoas, que até então não se conheciam nesta encarnação,
                            foram conduzidas, por motivos diferentes ao <strong>Centro Espírita Alvorecer Cristão</strong>, a nossa Casa Mãe, no bairro
                            de Pinheiros.
                        </p>
                        <p className="text-md text-slate-600 pb-4">
                            Neste Centro Espírita, também integrado a <strong>Aliança Espírita Evangélica</strong>, teve início a 15ª Turma de Escola de
                            Aprendizes do Evangelho. Foi nesta Escola que nos reencontramos e na proporção que conhecíamos a Jesus, a nós mesmos
                            e trabalhamos para nossa reforma interior, foi despertando em nosso íntimo uma chama, um ideal, uma vontade de seguir
                            e servir o Cristo. Aos poucos através dos nossos dirigentes e expositores, fomos descobrindo nossos talentos,
                            despertando o amor e a fraternidade, nos fortalecendo como um grupo, nos transformando em <strong>Discípulos de Jesus</strong>.
                        </p>
                        <h5 className="text-lg font-bold text-green-600">"Os meus discípulos serão conhecidos por muito se amarem." Jesus</h5>
                    </div>
                    <div className="w-full lg:w-1/2 ml-4">
                        <p className="text-md text-slate-600 pb-4">
                            Mas chegamos ao término da nossa Escola. Perguntávamos-nos o que fazer? De que forma poderíamos continuar
                            servindo a Jesus, servindo ao próximo?  Queríamos proporcionar a outras pessoas <strong>“a verdade, o caminho e a vida”</strong> que
                            o Evangelho de Jesus estava nos proporcionando através da <strong>Escola de Aprendizes do Evangelho</strong>, queríamos propagar as
                            sementes do Evangelho aos corações necessitados.
                        </p>
                        <p className="text-md text-slate-600 pb-4">
                            Surgiu então à proposta de uma frente de trabalho para este grupo, que era a abertura de uma nova Casa Espírita.
                            E lá estávamos nós, reunidos em um Domingo a tarde na nossa Casa Mãe <strong>- Centro Espírita Alvorecer Cristão</strong>, onde a todos
                            os presentes foi perguntado pela nossa dirigente se realmente estávamos dispostos a abraçarmos esta frente de trabalho
                            na seara de Jesus. E como se naquele momento resgatássemos em nossos corações e mentes os exemplos de todos aqueles
                            que um dia disseram sim para Jesus, lembramos de Paulo, de Pedro, Maria de Magdala, de João, de todos os discípulos,
                            de Estevão, de Bezerra de Menezes, enfim, de inúmeros outros seguidores do Mestre que perseveraram nesta seara.
                            Sabíamos que o trabalho não seria fácil, mas a resposta final da 15ª Turma de EAE foi sim.
                        </p>
                        <p className="text-md text-slate-600 pb-4">
                            Começamos então a nós planejar, para juntos abraçarmos este compromisso, que com certeza se estabeleceu no Plano Espiritual,
                            antes desta bendita oportunidade de reencarnação que Deus nos concedeu.
                        </p>
                        <p className="text-md text-slate-600 pb-4">
                            Chegou então o momento de definirmos um nome para esta nova Casa, e depois de várias sugestões, chegamos ao
                            nome <strong className="text-green-600">Centro Espírita Evangelho e Amor</strong>. E desde o dia da sua inauguração, no dia 13 de Outubro de 2002, é isso que procuramos
                            mostrar a todos que são conduzidos a ela, de que o verdadeiro Evangelho que Jesus nos deixou deve sempre caminhar com o Amor,
                            eles se complementam, devem sempre estar juntos em nossas vidas, pois só assim caminharemos juntos aos braços de Deus, nosso
                            Pai.
                        </p>
                        <p className="text-lg text-slate-600">
                            (*)  Título do Livro “Caminhos de Libertação” – Valentim Lorenzetti – Editora Aliança
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
