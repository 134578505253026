import React from 'react';
import logoImg from '../style/images/LogoCEEA.JPG';

export default function Topo() {
    return (
        <div className="hidden sm:block lg:mx-36 mt-4 mb-4">
            <div className="container">
                <div className="header-full-title flex items-start justify-start">
                    <a id="logo" href="/">
                        <img alt="CEEA" src={logoImg} className="w-32 h-18 lg:w-44 lg:h-24" />
                    </a>
                    <div className="ml-4">
                        <h1 className="text-2xl lg:text-4xl">
                            <a href="/" className="text-gray-900">Centro Espírita<span className="text-green-700"> Evangelho e Amor</span></a>
                        </h1>
                        <p className="text-gray-600 text-center mt-2 text-base lg:text-lg">Integrado à Aliança Espírita Evangélica</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
