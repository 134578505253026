import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';

export default function EvangelizacaoInfantil() {
    return (
        <div className="py-10">
            <div className="container mx-auto">
                <div className="text-center pb-14">
                    <h1 className="font-serif text-4xl font-medium text-green-600">Evangelização Infantil</h1>
                </div>
                <div className="flex flex-wrap items-start justify-center lg:justify-between">
                    <div className="w-full lg:w-2/3">
                        <h4 className="font-thin text-2xl mb-2 text-green-600">Aquisição da moral e o saber</h4>
                        <p className="text-lg text-slate-600">
                            A evangelização infantil tem como objetivo principal cultivar no espírito da criança, desde o alvorecer da vida,
                            o entendimento da prática das boas obras, a aquisição da moral e o saber.
                            As classes de evangelização infantil funcionam em aulas de 60 minutos e são divididas da seguinte forma:
                        </p>
                        <div className="flex flex-wrap">
                            <div className="md:w-1/2">
                                <p className="text-md text-slate-600"><FontAwesomeIcon icon={faCheck} className="mr-2" /> Ciclo Maternal – até 3 anos.</p>
                            </div>
                            <div className="md:w-1/2">
                                <p className="text-md text-slate-600"><FontAwesomeIcon icon={faCheck} className="mr-2" /> Ciclo Primário - de 7 a 9 anos.</p>
                            </div>
                            <div className="md:w-1/2">
                                <p className="text-md text-slate-600"><FontAwesomeIcon icon={faCheck} className="mr-2" /> Ciclo Jardim - de 4 a 6 anos.</p>
                            </div>
                            <div className="md:w-1/2">
                                <p className="text-md text-slate-600"><FontAwesomeIcon icon={faCheck} className="mr-2" /> Ciclo Intermediário - de 10 a 11 anos.</p>
                            </div>
                        </div>
                        <h4 className="font-thin text-2xl mb-2 text-green-600 mt-8">Escola de pais</h4>
                        <p className="text-lg text-slate-600">
                            A escola de pais reúne os colaboradores da casa e os responsáveis pelas crianças para uma
                            conversa sobre diversos assuntos relacionados à educação dos filhos, orientados sob a ótica da Doutrina Espírita.
                            Através da abordagem de temas atuais e a troca de experiências, criam-se, assim, novas oportunidades para que o
                            relacionamento entre pais e filhos seja ampliado, melhorando e harmonizando o ambiente familiar.
                        </p>
                    </div>
                    <div className="w-full lg:w-1/4 mt-8 lg:mt-0">
                        <div className="bg-red-500 text-white p-6 rounded-lg">
                            <p className="text-xl font-semibold mb-4 text-center">
                                <FontAwesomeIcon icon={faClock} className="mr-2" />
                                Horários
                            </p>
                            <ul className="list-none">
                                <li className="text-md text-center">Sábado às 9:00</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
