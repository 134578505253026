import React from 'react';

export default function Aee() {
    return (
        <div className="bg-gray-100 py-16">
            <div className="container mx-auto">
                <div className="text-center pb-14">
                    <h1 className="font-serif text-4xl font-medium text-green-600">Aliança Espírita Evangélica</h1>
                </div>
                <p className="text-center text-lg text-slate-600 pb-5">
                    Efetivar o ideal de Vivência do Espiritismo Religioso por meio de programas de
                    trabalho, estudo e fraternidade para o Bem da Humanidade.
                </p>
                <h4 className="font-thin text-2xl mb-2 text-green-600">História</h4>
                <p className="text-md text-slate-600 pb-4">
                    Na noite de 4 de dezembro de 1973, dirigentes de oito Centros Espíritas de São Paulo, procuraram Edgard Armond,
                    solicitando uma orientação para atuar de acordo com os programas definidos pelo Plano Espiritual, na década de 1950.
                    Dele, ouviram a proposta de elaborar um programa de auxílio mútuo: equipes de expositores, dirigentes e trabalhadores
                    compartilhariam de uma agenda comum, para fazer, juntos, o que isoladamente não teriam condições. Estava fundada a
                    Aliança Espírita Evangélica...
                    <a href="https://alianca.org.br/" target="_blank" rel="noopener noreferrer" className="text-green-600">Saiba mais</a>
                </p>
            </div>
        </div>
    );
}
