import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';

export default function Contato() {
    return (
        <div className="py-16 mb-0 pb-0">
            <div className="container mx-auto">
                <div className="flex flex-col items-center justify-center">
                    <h1 className="font-serif text-4xl font-medium text-green-600 mb-8">Contato</h1>
                    <p className="text-md lg:text-lg text-center text-slate-600 mb-4"><FontAwesomeIcon icon={faEnvelope} className="mr-2" />evangelhoeamor@terra.com.br</p>
                    <p className="text-md lg:text-lg text-center text-slate-600 mb-4"><FontAwesomeIcon icon={faMapLocationDot} className="mr-2" />Rua Tonelero, 186 - Vila Ipojuca, São Paulo - SP</p>
                </div>
            </div>
            <div className="maps">
                <iframe
                    title="mapa"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.081871737611!2d-46.707278323789204!3d-23.529557560426362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef87ea9e48a99%3A0xc971a0e8835c858a!2sRua%20Tonelero%2C%20186%20-%20Vila%20Ipojuca%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005056-000!5e0!3m2!1spt-BR!2sbr!4v1713121565791!5m2!1spt-BR!2sbr"
                    style={{ border: "none", width: "100%", height: "453px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </div>
    );
}
