import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import pdf from '../EvangelhoLar.pdf';

export default function EvangelhoLar() {
    return (
        <div className="py-10">
            <div className="container mx-auto">
                <div className="text-center pb-14">
                    <h1 className="font-serif text-4xl font-medium text-green-600">Evangelho no Lar</h1>
                </div>
                <div className="flex flex-wrap items-start justify-center lg:justify-between">
                    <div className="w-full lg:w-2/3">
                        <h4 className="font-thin text-2xl mb-2 text-green-600">Evangelho no lar</h4>
                        <p className="text-lg text-slate-600">
                            Nossa casa espírita oferece uma atividade de apoio para que você possa iniciar o evangelho no seu lar.
                            Com hora e dia marcados nosso grupo de implantação do evangelho no lar irá até sua casa.
                            Maiores informações poderão ser obtidas nos dias da assistência espiritual.
                        </p>
                        <h4 className="font-thin text-2xl mb-2 text-green-600 mt-8">O que é o evangelho no lar?</h4>
                        <p className="text-lg text-slate-600">
                            Trata-se do estudo do evangelho de Jesus em reunião familiar.
                            O evangelho no lar, realizado no ambiente doméstico, é precioso empreendimento que
                            traz diversos benefícios às pessoas que o praticam.
                            Permite ampla compreensão dos ensinamentos de Jesus e a prática destes, nos
                            ambientes em que vivemos. Ampliando-se o conhecimento sobre o evangelho, pode-se
                            oferecê-lo com mais segurança a outras criaturas, colaborando-se para a implantação do
                            Reino de Deus na Terra.
                            Com o estudo do evangelho de Jesus aprende-se a compreender e a conviver junto a
                            família humana.
                        </p>
                        <h4 className="font-thin text-2xl mb-2 text-green-600 mt-8">Por que fazer o evangelho no lar?</h4>
                        <p className="text-lg text-slate-600">
                            O cultivo dos bons pensamentos satura o ambiente doméstico de boas vibrações e facilita
                            a presença dos benfeitores espirituais, que trazem amparo e inspiração necessários para
                            superar as dificuldades que porventura surjam na vida.
                        </p>
                        <h4 className="font-thin text-2xl mb-2 text-green-600 mt-8">Como fazer o evangelho no lar?</h4>
                        <p>
                            <a href={pdf} target="_blank" rel="noopener noreferrer" className="inline-block px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-300">Roteiro</a>
                        </p>
                    </div>
                    <div className="w-full lg:w-1/4 mt-8 lg:mt-0">
                        <div className="bg-red-500 text-white p-6 rounded-lg">
                            <p className="text-xl font-semibold mb-4 text-center">
                                <FontAwesomeIcon icon={faClock} className="mr-2" />
                                Horários
                            </p>
                            <p className="text-md mb-4 text-center">Informações nos dias da assistência espiritual</p>
                            <ul className="list-none pl-5">
                                <li className="text-md mb-3 text-center">
                                    Segunda-Feira e Terça-Feira às 19:00
                                </li>
                                <li className="text-md mb-3 text-center">
                                    Quarta-Feira às 14:00
                                </li>
                                <li className="text-md text-center">
                                    Domingo às 18:30
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
