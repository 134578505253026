import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useNavContext } from '../contexts/navContext';
import { Link } from 'react-scroll';

export default function Rodape() {
    const { isNavFixed } = useNavContext();

    return (
        <div>
            {isNavFixed && (
                <div id="back-top" className="fixed bottom-5 right-5">
                    <Link
                        to="home"
                        smooth={true}
                        duration={500}
                        className="bg-gray-200 p-3 lg:p-4 rounded-lg text-center"
                        style={{ cursor: 'pointer' }}
                        offset={-50}
                    >
                        <FontAwesomeIcon icon={faChevronUp} />
                    </Link>
                </div>
            )}
            <div id="footer-widgets" className="bg-gray-700">
                <div className="container mx-auto py-8">
                    <div className="grid grid-cols-2 gap-8">
                        <div>
                            <h3 className="font-thin text-lg mb-2 text-green-600">Mapa do Site</h3>
                            <div className="list-none grid grid-cols-2 w-80">
                                <Link to="home" smooth={true} duration={500} className="text-slate-300" style={{ cursor: 'pointer' }} offset={-50}>Home</Link>
                                <Link to="atividades" smooth={true} duration={500} className="text-slate-300" style={{ cursor: 'pointer' }} offset={-50}>Atividades</Link>
                                <Link to="cursos" smooth={true} duration={500} className="text-slate-300" style={{ cursor: 'pointer' }} offset={-50}>Cursos</Link>
                                <Link to="sobre" smooth={true} duration={500} className="text-slate-300" style={{ cursor: 'pointer' }} offset={-50}>Sobre</Link>
                                <Link to="aee" smooth={true} duration={500} className="text-slate-300" style={{ cursor: 'pointer' }} offset={-50}>AEE</Link>
                                <Link to="contato" smooth={true} duration={500} className="text-slate-300" style={{ cursor: 'pointer' }} offset={-50}>Contato</Link>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-8">
                        <div>
                            <h6 className="text-sm lg:text-md text-slate-300">&copy; Centro Espírita Evangelho e Amor - Todos os direitos reservados.</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
