import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { faRoad } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

export default function Cursos() {
    return (
        <div className="bg-gray-100 py-16">
            <div className="container mx-auto">
                <div className="text-center pb-14">
                    <h1 className="font-serif text-4xl font-medium text-green-600">Cursos</h1>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                    <div className="bg-white rounded-lg shadow-md p-6 transition-transform duration-300 transform hover:-translate-y-1">
                        <h4 className="font-thin text-xl mb-2 text-green-600"><FontAwesomeIcon icon={faCubes} className="mr-2" />Curso Básico de Espiritismo</h4>
                        <p className="text-lg text-slate-600">
                            O Curso Básico de Espiritismo é informativo sobre a Doutrina Espírita, busca trazer ao aluno os aspectos
                            históricos e os princípios básicos do Espiritismo. Seu programa é rápido e de natureza simples e objetiva,
                            buscando uniformizar os conceitos da doutrina.
                        </p>
                    </div>
                    <div className="bg-white rounded-lg shadow-md p-6 transition-transform duration-300 transform hover:-translate-y-1">
                        <h4 className="font-thin text-xl mb-2 text-green-600"><FontAwesomeIcon icon={faRoad} className="mr-2" />Escola de Aprendizes do Evangelho</h4>
                        <p className="text-lg text-slate-600">
                            Trata-se de um programa organizado para proporcionar a vivência do Cristianismo como proposta essencial de
                            aperfeiçoamento moral da Humanidade através da Reforma Íntima do ser. Busca a renovação do homem em
                            seus sentimentos, pensamentos e atitudes, proporcionando-lhe experiências de verdadeiro auto-conhecimento
                            e despertamento de seus ideais divinos.
                        </p>
                    </div>
                    <div className="bg-white rounded-lg shadow-md p-6 transition-transform duration-300 transform hover:-translate-y-1">
                        <h4 className="font-thin text-xl mb-2 text-green-600"><FontAwesomeIcon icon={faHeart} className="mr-2" />Curso de Médiuns</h4>
                        <p className="text-lg text-slate-600">
                            Em geral, a mediunidade é exercida mecanicamente, sem objetivo definido, pelo simples fato de existir.
                            Mas isso é um erro. O médium deve saber por que é médium, as faculdades que possui, os limites de sua aplicação,
                            as conseqüências de sua ação, os objetivos a atingir e as responsabilidades que assume, tanto como indivíduo
                            quanto como membro da coletividade.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
