import React, { useState, useEffect } from 'react';
import fundoImg from '../style/images/imagem5.jpg';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import '../style/components/carousel.css'

export default function Carousel() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        {
            id: 1,
            title: 'Atividades',
            subtitle: 'Uma oportunidade para juntos refletirmos sobre o Evangelho de Jesus.',
            imageUrl: fundoImg,
            buttonHref: '#atividades'
        },
        {
            id: 2,
            title: 'Cursos',
            subtitle: 'Com programas organizados para proporcionar a vivência do Cristianismo.',
            imageUrl: fundoImg,
            buttonHref: '#cursos'
        },
        {
            id: 3,
            title: 'Venha participar da Terceira Turma de Mocidade',
            subtitle: 'A mocidade espírita é um curso de espiritismo para jovens e também um departamento do centro espírita dedicado à juventude.',
            imageUrl: fundoImg,
            buttonHref: 'https://mocidade.evangelhoeamor.com.br'
        }
    ];

    const goToSlide = (index) => {
        setCurrentSlide(index);
        resetAnimation();
    };

    const prevSlide = () => {
        setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
        resetAnimation();
    };

    const nextSlide = () => {
        setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
        resetAnimation();
    };

    const resetAnimation = () => {
        // Forçando um reflow para reiniciar a animação
        const element = document.querySelector('.carousel-text');
        element.classList.remove('animate-slide-left');
        void element.offsetWidth; // Trigger reflow
        element.classList.add('animate-slide-left');
    };

    const handleButtonClick = (href) => {
        if (href.includes('https')) {
            window.open(href, '_blank');
        }
        else {
            const element = document.querySelector(href);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
            resetAnimation();
        }, 5000);

        return () => clearInterval(interval);
    }, [slides.length]);

    return (
        <div className="relative">
            <div className="overflow-hidden">
                <div className="flex">
                    {slides.map((slide, index) => (
                        <div
                            key={slide.id}
                            className={`w-screen flex-shrink-0 transition-transform duration-500 transform translate-x-0'
                                }`}
                        >
                            <div className="relative">
                                <img src={slides[currentSlide].imageUrl} alt={slides[currentSlide].title} className="h-96 w-full" style={{ height: '490px', backgroundRepeat: 'no-repeat', backgroundSize: '100%' }} />
                                <div className="absolute inset-0 bg-black opacity-50"></div>
                                <div className="absolute inset-0 top-20 left-10 right-10 lg:left-60 lg:right-80">
                                    <div className="carousel-text animate-slide-left">
                                        <h1 className="text-2xl lg:text-4xl text-white">{slides[currentSlide].title}</h1>
                                        <h3 className="text-lg lg:text-2xl pt-5 text-yellow-400">{slides[currentSlide].subtitle}</h3>
                                        <div className="mt-14">
                                            <button
                                                onClick={() => handleButtonClick(slides[currentSlide].buttonHref)}
                                                className="inline-block px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-300"
                                            >
                                                Saiba mais
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="absolute bottom-5 left-0 right-0 flex justify-center mt-2">
                {slides.map((slide, index) => (
                    <button
                        key={slide.id}
                        onClick={() => goToSlide(index)}
                        className={`mx-1 w-3 h-3 rounded-full ${currentSlide === index ? 'bg-green-600' : 'bg-gray-300'
                            }`}
                    ></button>
                ))}
            </div>
            <button onClick={prevSlide} className="absolute left-10 top-1/2 transform -translate-y-1/2 bg-gray-300 px-4 py-4 rounded-lg z-10 hidden md:block">
                <ChevronLeftIcon className="h-5 w-5" />
            </button>
            <button onClick={nextSlide} className="absolute right-10 top-1/2 transform -translate-y-1/2 bg-gray-300 px-4 py-4 rounded-lg z-10 hidden md:block">
                <ChevronRightIcon className="h-5 w-5" />
            </button>
        </div>
    );
}
